body {
  margin: 0;
  padding: 0;
  font-family: poppins;
}

.root {
  min-height: calc(var(--vh, 1vh) * 100);
}

.flex {
  display: flex;
  flex: 1;
}

.centerV {
  align-items: center;
}

.centerH {
  justify-content: center;
}

.column {
  flex-direction: column;
}

.p-right {
  padding-right: 4px;
}

.p-left {
  padding-left: 4px;
}

.step {
  max-width: 600px;
  padding: 12px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
  position: relative;
}

.textfield {
  background: rgba(255, 255, 255, 0.8);
}

button {
  touch-action: manipulation;
}

.rdw-editor-toolbar {
  margin-bottom: 0px !important;
}

p {
  margin: 0px;
}

ul {
  padding: 0px;
  padding-left: 8px;
  margin: 0px;
}

.twoFactor {
  background: white;
  border: solid 1px rgba(155, 155, 155, 0.3);
  height: 45px;
  width: 45px;
  border-radius: 4px;
  margin: 2px;
  text-align: center;
  font-size: 20px !important;
  padding-left: 14px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #ffffff inset !important;
  background-color: #ffffff !important;
  background-clip: content-box !important;
}
